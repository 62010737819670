<template>
    <button
        class="tab-button"
        :class="{
            active: isActive,
            'tab-button--regular': !isDayTab,
            'tab-button--day': isDayTab,
            'pointer-events-none': $store.state.globals.isGenericErrorMessageVisible,
        }"
    >
        <span class="tab-button__inner leading-none">
            <span v-if="desktopLabelPrefix" class="hidden sm:inline"> {{ desktopLabelPrefix }}&nbsp;</span>
            {{ label }}
        </span>
    </button>
</template>

<script>
export default {
    name: 'TabButton',
    props: {
        label: {
            type: String,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
        desktopLabelPrefix: {
            type: String,
            required: false,
            default: null,
        },
        isDayTab: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.tab-button {
    @apply relative capitalize transition-all;

    &--regular {
        @apply opacity-80;
        &:hover {
            @apply opacity-100;
        }
        &.active {
            @apply opacity-100;
        }
    }

    &--day {
        @apply bg-grey-200 flex-1 text-grey-700/80 px-4 py-4 md:py-8;
        &:hover {
            @apply text-grey-700;
        }
        &.active {
            @apply text-grey-700;
        }
    }

    @keyframes slide {
        from {
            background-position: inherit;
        }
        to {
            background-position: 1.9rem;
        }
    }
    @media (hover: hover) {
        &:hover {
            @apply opacity-100;
        }
    }

    &__inner {
        @apply relative;
        &::after {
            @apply opacity-0 transition absolute w-full h-[6px] transition bg-contain bg-repeat-x bg-center left-0 bottom-[-8px]  opacity-0;
            content: '';
            background-position: inherit;
            background-image: url('~/assets/images/decorators/path.svg');
        }

        &:hover {
            &::after {
                @apply opacity-100;
                animation: slide 1s forwards;
            }
        }
    }

    &.active {
        @apply font-FibraOne-UltraBold opacity-100;
        .tab-button__inner {
            &::after {
                @apply opacity-100;
                background-position: 1.9rem;
            }
            &:hover {
                &::after {
                    animation: none;
                }
            }
        }
    }
}
</style>
