<template>
    <div>
        <h1 class="hidden">Jazzy rádió - Együtt vagyunk</h1>
        {{/* * */}}
        {{/* * */}}
        {{/* Hero */}}
        {{/* * */}}
        <MainHero v-if="!$store.state.isWebView" />

        <div class="container mb-20 lg:mb-44">
            {{/* * */}}
            {{/* Ad */}}
            <Ad desktop-id="adoceangemhupigonidfnh" mobile-id="adoceangemhunnqecioqbh" />

            {{/* * */}}
            {{/* * */}}
            {{/* Featured shows */}}
            {{/* * */}}
            <div v-if="highlightedShows.length && !$store.state.isWebView" class="mt-12 mb-4 font-FibraOne-UltraBold">
                Hallgass bele ezekbe is
            </div>
            <ProgramPromoCarousel v-if="highlightedShows.length && !$store.state.isWebView" :shows="highlightedShows" />

            {{/* * */}}
            {{/* Another ad just to be sure */}}
            <Ad desktop-id="adoceangemhumjnebwmjxc" mobile-id="adoceangemhukohlfvhfvg" />

            {{/* * */}}
            {{/* * */}}
            {{/* Previous tracks */}}
            {{/* * */}}
            <div class="flex flex-col sm:flex-row gap-5 pt-12 sm:pt-24 pb-4 sm:pb-14">
                <div class="relative flex-1">
                    <div
                        class="absolute top-1/2 -translate-y-1/2 left-1/2 sm:left-16 -translate-x-1/2 sm:translate-x-0 w-36 h-36 bg-grey-200 rounded-full z-0"
                    ></div>
                    <div
                        class="absolute -top-6 left-[calc(50%+4rem)] -translate-x-1/2 sm:translate-x-0 sm:left-48 w-3 h-3 bg-grey-200 rounded-full z-0"
                    ></div>
                    <div class="font-MasifaRdCn text-3xl sm:text-4xl uppercase relative z-5 text-center sm:text-left">
                        Korábban szólt <br />számainkat keresed?
                    </div>
                    <div
                        class="absolute hidden md:block -top-18 lg:-top-8 -right-12 lg:right-auto lg:left-[calc(50%-2rem)] translate-x-0 xl:-translate-x-1/2 w-64 h-32 z-0 bg-contain bg-center bg-no-repeat"
                        :style="`background-image: url(${require('~/assets/images/decorators/notes_and_little_circles.svg')})`"
                    ></div>
                </div>
                <div class="flex items-center justify-center sm:justify-end mt-8 sm:mt-0">
                    <p-button to="/playlist" label="Korábban szólt számok">
                        <template #icon-left>
                            <update-icon :size="18" />
                        </template>
                    </p-button>
                </div>
            </div>

            {{/* * */}}
            {{/* * */}}
            {{/* Weekly schedule */}}
            {{/* * */}}
            <div v-if="!$store.state.isWebView" class="pt-12 pb-4 font-FibraOne-UltraBold">Műsorújság</div>
            <div>
                <div ref="dayTabContainer" key="schedule-day-tabs" class="flex flex-nowrap mb-8 overflow-x-auto">
                    <TabButton
                        v-for="(day, i) in dayTabData"
                        :key="`day-tab-${i}`"
                        :is-active="i === activeDayTabIndex"
                        :is-day-tab="true"
                        :label="day"
                        @click.native="setActiveDayTabIndex(i)"
                    />
                </div>
                <div class="grid grid-cols-12 gap-4 mb-28">
                    <div class="col-span-12 lg:col-span-8 lg:col-start-3">
                        <TransitionGroup
                            :name="`fade-${activeDayTabIndex > prevDayTabIndex ? 'right' : 'left'}`"
                            class="relative"
                        >
                            <div
                                v-for="(day, i) in scheduleByDays"
                                v-show="activeDayTabIndex === i"
                                :key="`schedule-day-${i}`"
                            >
                                <div v-if="i === 3 && !areTodaysPreviousShowsVisible" class="text-center mb-4">
                                    <p-button
                                        label="Korábbi adások"
                                        class="p-button-outlined"
                                        @click.native="areTodaysPreviousShowsVisible = true"
                                    />
                                </div>

                                <TransitionGroup name="fade-opacity">
                                    <div
                                        v-for="(item, j) in day.schedule"
                                        v-show="
                                            i !== 3 ||
                                            (i === 3 &&
                                                ($dayjs(`${day.date} ${item.end}`).isSameOrAfter($dayjs()) ||
                                                    ($dayjs(`${day.date} ${item.end}`).isBefore($dayjs()) &&
                                                        areTodaysPreviousShowsVisible)))
                                        "
                                        :key="`schedule-day-show-${j}`"
                                        class="border-b border-grey-200"
                                    >
                                        <ProgramCard
                                            v-if="!item.show.programs.length"
                                            :to="`/musorok/${item.show.slug}`"
                                            :title="item.show.title"
                                            :color="item.show.color"
                                            :description="item.show.subtitle"
                                            :image="item.show.cover_image"
                                            :interval="`${item.start} - ${item.end}`"
                                            :is-live="
                                                $dayjs(`${day.date} ${item.start}`).isSameOrBefore($dayjs()) &&
                                                $dayjs(`${day.date} ${item.end}`).isAfter($dayjs())
                                            "
                                        />
                                        <div v-else>
                                            <ProgramCard
                                                v-for="(program, k) in item.show.programs"
                                                :key="`schedule-day-show-program-${k}`"
                                                :to="`/adasok/${program.slug}`"
                                                :stream-url="program.stream_url"
                                                :slug="program.slug"
                                                :title="item.show.title"
                                                :color="item.show.color"
                                                :description="program.title"
                                                :image="item.show.cover_image"
                                                :interval="`${program.start_time} - ${program.end_time}`"
                                                :is-live="
                                                    $dayjs(`${day.date} ${program.start_time}`).isSameOrBefore(
                                                        $dayjs()
                                                    ) && $dayjs(`${day.date} ${program.end_time}`).isAfter($dayjs())
                                                "
                                            />
                                        </div>
                                    </div>
                                </TransitionGroup>
                            </div>
                        </TransitionGroup>
                    </div>
                </div>
            </div>

            {{/* * */}}
            {{/* * */}}
            {{/* Bottom banner */}}
            {{/* * */}}
            <BannerCard
                v-if="bottomBannerData"
                :title="bottomBannerData.public_title"
                :label="bottomBannerData.surtitle"
                :button-text="bottomBannerData.button_text"
                :slug="bottomBannerData.button_url"
                :desc="bottomBannerData.content"
                :image="bottomBannerData.image"
                :color="bottomBannerData.scheme"
                :reversed="bottomBannerData.type"
                class="mb-12"
            />

            {{/* * */}}
            {{/* Yet another ad */}}
            <Ad desktop-id="adoceangemhuzjeletgohx" mobile-id="adoceangemhuxoorisrjjb" />
        </div>

        <SectionQuote
            :quote="quoteData.quote"
            :image="quoteData.image"
            :name="quoteData.name"
            :description="quoteData.profession"
        />
    </div>
</template>

<script>
import MainHero from '../components/Heroes/MainHero/MainHero';
import ProgramCard from '../components/Cards/ProgramCard/ProgramCard';
import ProgramPromoCarousel from '../components/Carousels/ProgramPromoCarousel/ProgramPromoCarousel';
import TabButton from '../components/UI/Buttons/TabButton';
import SectionQuote from '../components/Sections/SectionQuote/SectionQuote';
import BannerCard from '../components/Cards/BannerCard/BannerCard';
import Ad from '../components/Generic/Ad';
export default {
    name: 'Index',
    components: {
        MainHero,
        ProgramCard,
        ProgramPromoCarousel,
        TabButton,
        SectionQuote,
        BannerCard,
        Ad,
    },
    data() {
        return {
            prevDayTabIndex: 3,
            activeDayTabIndex: 3,
            canChangeDayTab: true, // lock the tay tabs until the tab change animation is done and then unlock them
            areTodaysPreviousShowsVisible: false,
            adScript: `document.write('<scr'+'ipt src="https://hugde.adocean.pl/_'+(new Date()).getTime()+'/ad.js?id=CiCbGTSlSDjer.fOReusiavgU1TO7XJB2I8seieo31L.h7/nc=0/gdpr=0/gdpr_consent=/redir=" language="javascript"></scr'+'ipt>');`,
        };
    },
    head() {
        const script = [];

        return {
            script,
        };
    },
    computed: {
        highlightedShows() {
            return this.$store.state.data.highlightedShows;
        },
        scheduleByDays() {
            return this.$store.state.data.scheduleByDays;
        },
        quoteData() {
            return this.$store.state.data.quote;
        },
        dayTabData() {
            return this.$store.state.data.scheduleByDays.map((x) => {
                const _diffFromToday = this.$dayjs(x.date).diff(this.$dayjs().format('YYYY-MM-DD'), 'day');

                if (_diffFromToday === 0) {
                    return 'Ma';
                } else if (_diffFromToday === -1) {
                    return 'Tegnap';
                } else if (_diffFromToday === 1) {
                    return 'Holnap';
                } else {
                    return x.day;
                }
            });
        },
        bottomBannerData() {
            return this.getPromotionBox('index-bottom');
        },
    },
    mounted() {
        if (this.$device.isMobile) {
            const dayTabContainer = this.$refs.dayTabContainer;
            const activeTab = dayTabContainer.querySelector(`.tab-button--day.active`);
            const dayTabContainerRect = dayTabContainer.getBoundingClientRect();
            const activeTabRect = activeTab.getBoundingClientRect();

            dayTabContainer.scroll(activeTabRect.left + activeTabRect.width / 2 - dayTabContainerRect.width / 2, 0);
        }
    },
    methods: {
        setActiveDayTabIndex(i) {
            this.activeDayTabIndex = i;

            if (!this.canChangeDayTab) {
                return;
            }

            this.canChangeDayTab = false;

            setTimeout(() => {
                this.prevDayTabIndex = this.activeDayTabIndex;
                this.canChangeDayTab = true;
            }, 450);
        },
    },
};
</script>

<style scoped></style>
