<template>
    <div
        v-if="!$store.state.isWebView"
        ref="ad"
        class="flex justify-center flex-col"
        :class="{ 'mt-8 mb-4 lg:mt-12 lg:mb-4': !!adClientHeight }"
    >
        <div class="hidden lg:block">
            <div :id="desktopId"></div>
        </div>
        <div class="block lg:hidden">
            <div :id="mobileId"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Ad',
    props: {
        desktopId: {
            type: String,
            required: true,
        },
        mobileId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            adClientHeight: 0,
        };
    },
    mounted() {
        if (typeof window.ado?.onAfterRenderAd !== 'function') {
            return;
        }

        window.ado.onAfterRenderAd(() => {
            setTimeout(() => {
                if (this.$refs.ad.querySelector('img')) {
                    this.adClientHeight = this.$refs.ad.querySelector('img').clientHeight;
                }
            }, 100);
        });
    },
};
</script>

<style scoped></style>
