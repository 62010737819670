<template>
    <div class="wrapper relative overflow-hidden sm:-ml-5">
        <TransitionGroup
            key="trackbar-transition-group"
            tag="div"
            :name="`fade-trackbar-track-width${target !== 'jazzylive' ? '-reverse' : ''}`"
            class="program-card-container flex items-center h-36 sm:h-44 lg:h-52 overflow-x-auto lg:overflow-hidden pl-5"
        >
            <div
                v-for="(item, i) in items"
                :key="`item-${target === 'jazzylive' ? item._interval : item.sh_id}`"
                class="w-72 sm:w-96 md:w-[22.5rem] lg:w-[20rem] xl:w-[25rem]"
            >
                <ProgramCard
                    class="min-w-72 sm:min-w-96 md:min-w-[22.5rem] lg:min-w-[20rem] xl:min-w-[25rem]"
                    :interval="target === 'jazzylive' ? item._interval : getDuration(item.duration)"
                    :channel="target"
                    :to="target === 'jazzylive' ? `/musorok/${item.slug}` : null"
                    :color="target === 'jazzylive' ? item.color : null"
                    :title="target === 'jazzylive' ? item.title : item.song.title"
                    :description="target === 'jazzylive' ? null : item.song.artist"
                    :image="target === 'jazzylive' ? item.cover_image : item.song.art"
                    :has-rectangle-image="target !== 'jazzylive'"
                    :is-next-in-track-list="target === 'jazzylive' && i === 0"
                    :is-coming-soon-in-track-list="target === 'jazzylive' && i === 1"
                />
            </div>
        </TransitionGroup>
    </div>
</template>

<script>
import ProgramCard from '../../Cards/ProgramCard/ProgramCard';

export default {
    name: 'MainHeroTracksBar',
    components: {
        ProgramCard,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        target: {
            type: String,
            required: true,
        },
    },
    methods: {
        getDuration(rawDurationInSeconds) {
            const hours = Math.floor(rawDurationInSeconds / 60 / 60);
            const minutes = Math.floor(rawDurationInSeconds / 60);
            const seconds = String(rawDurationInSeconds % 60).padStart(2, '0');

            let ret = '';

            if (hours) {
                ret += `${hours}:`;
            }
            if (minutes) {
                ret += `${minutes}:`;
            }
            ret += seconds;

            return ret;
        },
    },
};
</script>

<style scoped>
.wrapper::after {
    content: '';
    @apply block absolute top-0 right-0 w-14 h-full bg-gradient-to-r from-transparent to-grey-700 hidden sm:block lg:hidden;
}
</style>
