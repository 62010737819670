<template>
    <div class="grid grid-cols-2 bg-[#1c1d29] text-white">
        <div
            class="col-span-2 lg:col-span-1 bg-cover bg-no-repeat bg-center aspect-w-16 aspect-h-9 bg-center"
            :class="reversed === 1 ? 'order-2' : 'order-1'"
            :style="`background-image: url(${image || require('~/assets/images/_dev/banner.png')})`"
        ></div>
        <div
            class="col-span-2 lg:col-span-1 px-5 pb-5 pt-4 sm:px-12 sm:pb-12 sm:pt-10 relative"
            :class="reversed === 1 ? 'order-1' : 'order-2'"
        >
            <div
                class="absolute -right-1 w-44 h-4 bg-contain bg-no-repeat bg-right-bottom"
                :style="`background-image: url(${require('~/assets/images/decorators/zigzag_curly_1.svg')}); top: -5px`"
            ></div>
            <span v-if="label" class="text-xs text-red-500 mb-2" :style="`color:${color}`">{{ label }}</span>
            <div v-if="title" class="section-title mb-4">{{ title }}</div>
            <div class="text-grey-325 mb-5" v-html="desc"></div>
            <p-button
                :to="slug"
                external
                :label="buttonText"
                :style="`background-color:${color} ; border-color: ${color}`"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BannerCard',
    props: {
        label: {
            type: String,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        desc: {
            type: String,
            required: false,
            default: null,
        },
        buttonText: {
            type: String,
            required: false,
            default: null,
        },
        image: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
        reversed: {
            type: [Boolean, Number],
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
