<template>
    <div class="grid grid-cols-12 gap-5 pt-10 md:pt-24 lg:pt-24 2xl:pt-32 pb-14 md:pb-20 lg:pb-28">
        {{/* * */}}
        {{/* Text content */}}
        {{/* * */}}
        <div class="col-span-12 lg:col-span-6 lg:col-start-2 order-2 lg:order-1 text-center sm:text-left lg:pr-7">
            <div class="text-sm inline-flex pt-2 sm:pt-12 mb-2">
                <LiveBadge v-if="channel === 'jazzylive' && interval" text="Élő:" />
                <span v-else>Most szól</span>
                <span v-if="channel === 'jazzylive' && interval" class="ml-2">{{ interval }}</span>
            </div>
            <div v-if="channel === 'jazzylive'">
                <nuxt-link :to="`/musorok/${$store.state.channel.currentJazzyLiveShow.show.slug}`" class="heading-1">
                    {{ title }}
                </nuxt-link>
            </div>
            <div v-else class="heading-1 line-clamp-3">{{ title }}</div>
            <div class="text-grey-325 text-sm lg:text-base sm:mt-4 lg:pr-24">
                {{ description }}
            </div>
            <div class="flex mt-8 justify-center sm:justify-start">
                <p-button :label="playButtonLabel" @click.native="handlePlayButtonClick">
                    <template #icon-left>
                        <pause-icon
                            v-if="$store.state.player.isPlaying && $store.state.player.playingChannelName === channel"
                            :size="24"
                        />
                        <play-icon v-else :size="24" />
                    </template>
                </p-button>
                <p-button
                    v-if="$device.isDesktop"
                    label="Popup"
                    class="p-button-outlined ml-4"
                    @click.native="$nuxt.$emit('openPopupPlayer')"
                >
                    <template #icon-left>
                        <open-in-new-icon :size="18" />
                    </template>
                </p-button>
                <p-button
                    v-if="
                        channel === 'jazzylive' && $store.state.channel.currentJazzyLiveShow.program?.video_stream_url
                    "
                    :to="$store.state.channel.currentJazzyLiveShow.program.video_stream_url"
                    label="Stream"
                    class="p-button-outlined ml-4"
                >
                    <template #icon-left>
                        <video-icon :size="18" />
                    </template>
                </p-button>
            </div>
        </div>
        {{/* * */}}
        {{/* Image */}}
        {{/* * */}}
        <div class="col-span-6 col-start-4 lg:col-span-4 lg:pl-6 order-1 lg:order-2">
            <div
                class="relative aspect-w-1 aspect-h-1"
                :class="{ 'rounded-full overflow-hidden': channel === 'jazzylive' }"
            >
                <div class="z-[-1]"></div>
                <img
                    class="absolute w-full h-full top-0 left-0 object-contain object-center z-5"
                    :src="image"
                    :alt="title"
                    @error="(e) => $getChannelFallbackImage(e, channel, 'large')"
                />
                <div></div>
                <div
                    v-if="color"
                    class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] h-[90%] z-0 rounded-full"
                    :style="`background-color: ${color}`"
                ></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainHeroContentPanel',
    props: {
        channel: {
            type: String,
            required: true,
        },
    },
    computed: {
        title() {
            if (this.channel === 'jazzylive') {
                return this.$store.state.channel.currentJazzyLiveShow.show.title;
            }
            return this.$store.state.channel[this.channel]?.now_playing.song.title;
        },
        image() {
            if (this.channel === 'jazzylive') {
                return this.$store.state.channel.currentJazzyLiveShow.show.cover_image;
            }
            return this.$store.state.channel[this.channel]?.now_playing.song.art;
        },
        color() {
            if (this.channel === 'jazzylive') {
                return this.$store.state.channel.currentJazzyLiveShow.show.color;
            }
            return null;
        },
        description() {
            if (this.channel === 'jazzylive') {
                if (this.$store.state.channel.currentJazzyLiveShow.program) {
                    return this.$store.state.channel.currentJazzyLiveShow.program.title;
                } else {
                    return this.$store.state.channel.currentJazzyLiveShow.show.subtitle;
                }
            }
            return this.$store.state.channel[this.channel]?.now_playing.song.artist;
        },
        interval() {
            if (this.channel === 'jazzylive') {
                if (this.$store.state.channel.currentJazzyLiveShow.isFallback) {
                    return null;
                }
                if (this.$store.state.channel.currentJazzyLiveShow.program) {
                    return `${this.$store.state.channel.currentJazzyLiveShow.program.start_time} - ${this.$store.state.channel.currentJazzyLiveShow.program.end_time}`;
                } else {
                    return `${this.$store.state.channel.currentJazzyLiveShow.schedule.start} - ${this.$store.state.channel.currentJazzyLiveShow.schedule.end}`;
                }
            }
            return null;
        },
        playButtonLabel() {
            if (this.$store.state.player.isPlaying && this.$store.state.player.playingChannelName === this.channel) {
                return 'Szünet';
            } else if (this.channel === 'jazzylive') {
                return 'Hallgatás élőben';
            }
            return 'Hallgatás';
        },
    },
    methods: {
        handlePlayButtonClick() {
            // If we are on the channel's tab that is currently being played too, we pause the player
            if (this.$store.state.player.isPlaying && this.$store.state.player.playingChannelName === this.channel) {
                this.$nuxt.$emit('pauseMainPlayer');
                return;
            }

            // Else we update the player with the current channel's data (the channel we see the tab of in the hero).
            // This will result in redundant updates too, but it doesn't do any harm, so it is what it is
            const payload = {};

            if (this.channel === 'jazzylive') {
                payload.src = this.$store.state.channel.jazzy.station.listen_url;
                payload.color = this.$store.state.channel.currentJazzyLiveShow.color;
                payload.artist = 'Élőben most';
            } else {
                payload.src = this.$store.state.channel[this.channel].station.listen_url;
                payload.artist = this.$store.state.channel[this.channel]?.now_playing.song.artist;
            }

            payload.title = this.title;
            payload.image = this.image;

            this.$store.commit('player/setPlayingChannelName', this.channel);
            this.$store.commit('player/setPlayingProgram', null);
            this.$nuxt.$emit('updateMainPlayerStreamUrl', payload.src);
            this.$nuxt.$emit('playMainPlayer');
        },
    },
};
</script>

<style scoped></style>
