<template>
    <nuxt-link v-if="to" :to="to" class="py-4 w-full">
        <ImageBadge
            mobile-size="lg"
            desktop-size="xl"
            :alt="title"
            :color="color"
            :image="image"
            class="mx-auto mb-3"
        />

        <div class="entity-card__title text-center font-MasifaRdCn text-2xl mb-5 sm:mx-3 uppercase">
            {{ title }}
        </div>
        <div class="entity-card__description text-center text-xs max-w-72 mx-auto">
            {{ description }}
        </div>
    </nuxt-link>

    <div v-else class="py-4 w-full">
        <ImageBadge
            mobile-size="lg"
            desktop-size="xl"
            :color="color"
            :alt="title"
            :image="image"
            class="mx-auto mb-3"
        />

        <div class="entity-card__title text-center font-MasifaRdCn text-2xl mb-5 sm:mx-3 uppercase">
            {{ title }}
        </div>
        <div v-if="description" class="entity-card__description text-center text-xs max-w-72 mx-auto">
            {{ description }}
        </div>
    </div>
</template>

<script>
import ImageBadge from '../../UI/ImageBadge';

export default {
    name: 'Entity',
    components: {
        ImageBadge,
    },
    props: {
        to: {
            type: String,
            required: false,
            default: null,
        },
        image: {
            type: String,
            required: false,
            default: null,
        },
        color: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style>
.entity-card__title {
    @apply text-grey-700;
}
.entity-card__description {
    @apply text-grey-500;
}

.theme-dark {
    .entity-card__title {
        @apply text-white;
    }
    .entity-card__description {
        @apply text-grey-350;
    }
}
</style>
