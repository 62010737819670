<template>
    <div :id="compId"></div>
</template>

<script>
export default {
    name: 'VueScriptComponent',
    props: {
        script: {
            type: String,
            default: null,
        },
    },
    data() {
        const randomID = Math.random().toString(36).substring(7);
        return {
            compId: window.performance.now() + '-' + randomID,
        };
    },
    mounted() {
        const addEl = new Promise((resolve, reject) => {
            this.$nextTick(() => {
                this.$postscribe(`#${this.compId}`, `${this.script}`, {
                    done: function (x) {
                        resolve(x);
                    },
                });
                resolve();
            });
        });
        addEl.then(function (result) {
            console.log(result);
        });
    },
};
</script>

<style scoped></style>
