<template>
    <div class="flex items-center py-4 w-full">
        <ImageBadge
            mobile-size="sm"
            desktop-size="md"
            :is-rectangle="hasRectangleImage"
            :color="color"
            :alt="title"
            :channel="channel"
            :image="image"
            class="mr-4"
        />
        <div class="pr-4 sm:pr-8 lg:pr-10 flex-1">
            <div class="flex items-center gap-1 mb-2">
                <span v-if="isNextInTrackList" class="font-FibraOne-UltraBold text-red-500 text-xs">Következik:</span>
                <span v-else-if="isComingSoonInTrackList" class="program-card__interval text-xs">Hamarosan:</span>
                <div class="flex items-center text-xs">
                    <LiveBadge v-if="isLive" text="Élő:" class="text-red-500 mr-1" />
                    <span class="program-card__interval">{{ interval }}</span>
                </div>
            </div>
            <div
                v-if="title"
                class="program-card__title font-MasifaRdCn text-lg sm:text-2xl mb-1 uppercase"
                :class="{ 'line-clamp-2': !preventLineClamp }"
            >
                {{ title }}
            </div>
            <div v-if="description" class="program-card__description text-xs lg:w-10/12">
                {{ description }}
            </div>
        </div>
    </div>
</template>

<script>
import ImageBadge from '../../UI/ImageBadge';

export default {
    name: 'ProgramCardInner',
    components: {
        ImageBadge,
    },
    props: {
        image: {
            type: [String, Boolean],
            required: false,
            default: null,
        },
        interval: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        color: {
            type: String,
            required: false,
            default: null,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        channel: {
            type: String,
            required: false,
            default: null,
        },
        preventLineClamp: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasRectangleImage: {
            type: Boolean,
            required: false,
            default: false,
        },
        isNextInTrackList: {
            type: Boolean,
            required: false,
            default: false,
        },
        isComingSoonInTrackList: {
            type: Boolean,
            required: false,
            default: false,
        },
        isLive: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.program-card__interval {
    @apply text-grey-500;
}
.program-card__title {
    @apply text-grey-700;
}
.program-card__description {
    @apply text-grey-500;
}

.theme-dark {
    .program-card__interval {
        @apply text-grey-325;
    }
    .program-card__title {
        @apply text-white;
    }
    .program-card__description {
        @apply text-grey-350;
    }
}
</style>
