<template>
    <div id="featured-programs-carousel" class="swiper w-[calc(100%+2rem)] -ml-4 pl-4 md:pl-0 md:ml-0 md:w-full">
        <div class="swiper-wrapper">
            <div v-for="show in shows" :key="`featured-program-${show.id}`" class="swiper-slide">
                <EntityCard
                    :to="`/musorok/${show.slug}`"
                    :image="show.cover_image"
                    :title="show.title"
                    :description="show.subtitle"
                    :color="show.color"
                />
            </div>
        </div>
    </div>
</template>

<script>
import EntityCard from '../../Cards/EntityCard/EntityCard';

export default {
    name: 'ProgramPromoCarousel',
    components: {
        EntityCard,
    },
    props: {
        shows: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.programPromoCarousel = new this.$swiper('#featured-programs-carousel', {
            slidesPerView: 1.5,
            spaceBetween: 20,
            resistance: true,
            resistanceRatio: 0,
            speed: 750,
            breakpoints: {
                576: {
                    slidesPerView: 2.2,
                },
                768: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
            },
        });
    },
    beforeDestroy() {
        if (this.programPromoCarousel) {
            this.programPromoCarousel.destroy();
        }
    },
};
</script>

<style scoped></style>
