<template>
    <div v-if="$device.isDesktop && !isImageOnly">
        <Transition name="fade-in-background-video">
            <video
                v-if="isMounted"
                class="w-full h-full object-cover absolute z-0 video opacity-60"
                muted
                loop
                autoplay
                playsinline
                :src="require('~/assets/videos/jazzy-smoke.mp4')"
            ></video>
        </Transition>
    </div>
    <div
        v-else
        class="absolute top-0 left-0 w-full h-full bg-cover bg-center"
        :style="`background-image: url(${require('~/assets/images/backgrounds/mobile-hero-bg.jpg')})`"
    ></div>
</template>

<script>
export default {
    name: 'MainHeroBackground',
    props: {
        isImageOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isMounted: false,
        };
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true;
        }, 100);
    },
};
</script>

<style scoped></style>
