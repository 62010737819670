<template>
    <div class="quote-section pt-24 pb-24 md:pt-40 md:pb-44">
        <div class="container">
            <div class="grid grid-cols-12">
                <div
                    class="col-span-12 md-col-span-10 md-col-start-2 lg:col-span-8 lg:col-start-3 flex flex-col items-center"
                >
                    <div
                        class="text-2xl md:text-3xl lg:text-4xl lg:text-5xl mb-8 text-white italic text-center"
                        style="font-family: 'Times New Roman', serif"
                    >
                        — {{ quote }}
                    </div>
                    <div class="inline-flex items-center">
                        <div
                            class="w-16 h-16 min-w-16 min-h-16 bg-cover bg-center rounded-full mr-4"
                            :style="`background-image: url(${image})`"
                        ></div>
                        <div>
                            <div class="font-FibraOne-UltraBold text-white">{{ name }}</div>
                            <div class="text-xs text-grey-325">{{ description }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SectionQuote',
    props: {
        quote: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
};
</script>

<style>
.quote-section {
    @apply bg-grey-700;
}

.theme-dark .quote-section {
    @apply bg-black;
}
</style>
