<template>
    <div class="flex items-center w-full">
        <nuxt-link v-if="to" :to="to" class="w-full">
            <ProgramCardInner
                :image="image"
                :interval="interval"
                :title="title"
                :color="color"
                :description="description"
                :channel="channel"
                :prevent-line-clamp="preventLineClamp"
                :has-rectangle-image="hasRectangleImage"
                :is-next-in-track-list="isNextInTrackList"
                :is-coming-soon-in-track-list="isComingSoonInTrackList"
                :is-live="isLive"
            />
        </nuxt-link>
        <ProgramCardInner
            v-else
            :image="image"
            :interval="interval"
            :title="title"
            :color="color"
            :description="description"
            :channel="channel"
            :prevent-line-clamp="preventLineClamp"
            :has-rectangle-image="hasRectangleImage"
            :is-next-in-track-list="isNextInTrackList"
            :is-coming-soon-in-track-list="isComingSoonInTrackList"
            :is-live="isLive"
        />
        <p-button v-if="isLive" icon-only class="p-button-xs" @click.native="handlePlayButtonClick">
            <template #icon-left>
                <pause-icon
                    v-if="$store.state.player.isPlaying && $store.state.player.playingChannelName === 'jazzylive'"
                    :size="20"
                />
                <play-icon v-else :size="20" />
            </template>
        </p-button>
        <p-button v-else-if="streamUrl" icon-only class="p-button-xs" @click.native="handlePlayButtonClick">
            <template #icon-left>
                <pause-icon
                    v-if="$store.state.player.isPlaying && $store.state.player.playingProgram?.slug === slug"
                    :size="20"
                />
                <play-icon v-else :size="20" />
            </template>
        </p-button>
    </div>
</template>

<script>
import ProgramCardInner from './ProgramCardInner';

export default {
    name: 'ProgramCard',
    components: {
        ProgramCardInner,
    },
    props: {
        to: {
            type: String,
            required: false,
            default: null,
        },
        image: {
            type: [String, Boolean],
            required: false,
            default: null,
        },
        interval: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        slug: {
            type: String,
            required: false,
            default: null,
        },
        color: {
            type: String,
            required: false,
            default: null,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        channel: {
            type: String,
            required: false,
            default: null,
        },
        streamUrl: {
            type: String,
            required: false,
            default: null,
        },
        preventLineClamp: {
            type: Boolean,
            required: false,
            default: false,
        },
        isNextInTrackList: {
            type: Boolean,
            required: false,
            default: false,
        },
        isComingSoonInTrackList: {
            type: Boolean,
            required: false,
            default: false,
        },
        hasRectangleImage: {
            type: Boolean,
            required: false,
            default: false,
        },
        isLive: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    created() {
        // prop validator didn't fire for some reason, so I'm doing the validation here
        if (this.slug && !this.streamUrl) {
            console.error('slug is defined but stream url is missing');
        }
        if (!this.slug && this.streamUrl) {
            console.error('stream url is defined but slug is missing');
        }
    },
    methods: {
        handlePlayButtonClick() {
            // If the program's data is already loaded into the player, just toggle the player
            if (
                (this.isLive && this.$store.state.player.playingChannelName === 'jazzylive') ||
                this.$store.state.player.playingProgram?.slug === this.slug
            ) {
                this.$nuxt.$emit('toggleMainPlayerPlayState');
                return;
            }

            // Else we update the player with the selected program's id.
            // This will result in redundant updates too, but it doesn't do any harm, so it is what it is
            const payload = {};

            payload.title = this.title;
            payload.image = this.image;

            if (this.isLive) {
                payload.src = this.$store.state.channel.jazzy.station.listen_url;
                payload.color = this.$store.state.channel.currentJazzyLiveShow.color;
                payload.artist = 'Élőben most';
            } else {
                payload.src = this.streamUrl;
                payload.color = this.color;
                payload.artist = this.description;
                payload.slug = this.slug;
            }

            if (this.isLive) {
                this.$store.commit('player/setPlayingChannelName', 'jazzylive');
                this.$store.commit('player/setPlayingProgram', null);
            } else {
                this.$store.commit('player/setPlayingChannelName', null);
                this.$store.commit('player/setPlayingProgram', payload);
            }

            this.$nuxt.$emit('updateMainPlayerStreamUrl', payload.src);
            this.$nuxt.$emit('playMainPlayer');
        },
    },
};
</script>

<style></style>
